export const state = () => ({
  user: {
    isLogin: false,
    phone: null,
    access_token: null,
    email: null,
    active_tickets: [],
    history_tickets: [],
    passangersHistory: [],
  },
  cookie: true,

})

export const mutations = {
  setRefreshTicket(state, data) {
    state.user.active_tickets = data.active_tickets
    state.user.history_tickets = data.history_tickets
  },
  setUser(state, data) {
    state.user = data
  },
  logOut(state, rootState) {
    state.user = {isLogin: false}
  },
  setProp(state, data) {
    state[data.obj][data.key] = data.prop;
  },
  addPassangersHistory(state, data) {
    try {
      Object.keys(data).forEach(item => {
        let checkedPass = false;
        delete data[item].places;
        delete data[item].doc_type;
        delete data[item].sell_type;
        console.log(state.user.passangersHistory)
        state.user.passangersHistory.forEach(oldPass => {
          console.log(oldPass)
          if (JSON.stringify(data[item]) === JSON.stringify(oldPass)) {
            checkedPass = true;
          }
        })
        if (!checkedPass) {
          state.user.passangersHistory.push(JSON.parse(JSON.stringify(data[item])));
        }
      });
    } catch (e) {
      console.log(e)
    }


  },
  removePassanger(state, data) {
    state.user.passangersHistory.splice(data, 1)
  },
  setCookie(state) {
    state.cookie = false;
  }
}

export const actions = {
  async login({state, commit, dispatch}, val) {
    let response = null;
    await this.$axios.post('api/v1/account/login', val)
      .then(resp => {
        if (resp.data.error) {
          response = resp.data;
        } else {
          commit('setUser', resp.data.response)
          response = resp.data;
        }
      });
    return response;
  },
  async refreshTicket({state, commit}) {
    await this.$axios.post('api/v1/account/get-profile', {access_token: state.user.access_token})
      .then(resp => {
        commit('setRefreshTicket', resp.data.response)
      });
  }
}


export const getters = {
  getPropByKey: state => data => {
    return state.user[data];
  },
  getCookie: state => {
    return state.cookie
  },
}
