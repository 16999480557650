export const state = () => ({
  findDeparture: [],
  departure: null,
})

export const mutations = {
  setFindedDeparture(state, data) {
    state.findDeparture = data;
  },
  setDeparture(state, data) {
    state.departure = data
  },
  sortByParam(state, data) {
    state.findDeparture.sort((a, b) => {
      let itemA = data === 'time_in_way' ? a[data].split(':')[0] * 60 + a[data].split(':')[1] * 1 : a[data],
        itemB = data === 'time_in_way' ? b[data].split(':')[0] * 60 + b[data].split(':')[1] * 1 : b[data];

      if (itemA > itemB) {
        return 1;
      } else if (itemA < itemB) {
        return -1;
      }
      return 0;
    });
  },
  clearFindedData(state) {
    state.findDeparture = [];
  }
}

export const actions = {}

export const getters = {
  getFindedDeparture: state => {
    return state.findDeparture;
  },
  getDeparture: state => {
    return state.departure
  }
}
