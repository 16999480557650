import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3c425ae5 = () => interopDefault(import('../pages/carriers.vue' /* webpackChunkName: "pages/carriers" */))
const _04057be1 = () => interopDefault(import('../pages/comments/index.vue' /* webpackChunkName: "pages/comments/index" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _055622cb = () => interopDefault(import('../pages/politics.vue' /* webpackChunkName: "pages/politics" */))
const _3b12c806 = () => interopDefault(import('../pages/rules.vue' /* webpackChunkName: "pages/rules" */))
const _0a6de6e6 = () => interopDefault(import('../pages/order/error.vue' /* webpackChunkName: "pages/order/error" */))
const _b56e8470 = () => interopDefault(import('../pages/order/success.vue' /* webpackChunkName: "pages/order/success" */))
const _895871dc = () => interopDefault(import('../pages/partners/route/index.vue' /* webpackChunkName: "pages/partners/route/index" */))
const _33e832e1 = () => interopDefault(import('../pages/account/_id/index.vue' /* webpackChunkName: "pages/account/_id/index" */))
const _f0028192 = () => interopDefault(import('../pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _4a32d71f = () => interopDefault(import('../pages/bus-search/_city_a/index.vue' /* webpackChunkName: "pages/bus-search/_city_a/index" */))
const _8659faee = () => interopDefault(import('../pages/comments/_id.vue' /* webpackChunkName: "pages/comments/_id" */))
const _79e495b8 = () => interopDefault(import('../pages/page/_id.vue' /* webpackChunkName: "pages/page/_id" */))
const _cdeb85f2 = () => interopDefault(import('../pages/account/_id/active.vue' /* webpackChunkName: "pages/account/_id/active" */))
const _42fa083a = () => interopDefault(import('../pages/account/_id/history.vue' /* webpackChunkName: "pages/account/_id/history" */))
const _5517d386 = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/index.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/index" */))
const _e918a308 = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/order/index.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/order/index" */))
const _f664ea66 = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/search.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/search" */))
const _03d31fe4 = () => interopDefault(import('../pages/bus-search/_city_a/_city_b/order/_id.vue' /* webpackChunkName: "pages/bus-search/_city_a/_city_b/order/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _999675e2,
    name: "blog"
  }, {
    path: "/carriers",
    component: _3c425ae5,
    name: "carriers"
  }, {
    path: "/comments",
    component: _04057be1,
    name: "comments"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq"
  }, {
    path: "/partners",
    component: _1c6abc50,
    name: "partners"
  }, {
    path: "/politics",
    component: _055622cb,
    name: "politics"
  }, {
    path: "/rules",
    component: _3b12c806,
    name: "rules"
  }, {
    path: "/order/error",
    component: _0a6de6e6,
    name: "order-error"
  }, {
    path: "/order/success",
    component: _b56e8470,
    name: "order-success"
  }, {
    path: "/partners/route",
    component: _895871dc,
    name: "partners-route"
  }, {
    path: "/account/:id",
    component: _33e832e1,
    name: "account-id"
  }, {
    path: "/blog/:id",
    component: _f0028192,
    name: "blog-id"
  }, {
    path: "/bus-search/:city_a",
    component: _4a32d71f,
    name: "bus-search-city_a"
  }, {
    path: "/comments/:id",
    component: _8659faee,
    name: "comments-id"
  }, {
    path: "/page/:id?",
    component: _79e495b8,
    name: "page-id"
  }, {
    path: "/account/:id?/active",
    component: _cdeb85f2,
    name: "account-id-active"
  }, {
    path: "/account/:id?/history",
    component: _42fa083a,
    name: "account-id-history"
  }, {
    path: "/bus-search/:city_a?/:city_b",
    component: _5517d386,
    name: "bus-search-city_a-city_b"
  }, {
    path: "/bus-search/:city_a?/:city_b?/order",
    component: _e918a308,
    name: "bus-search-city_a-city_b-order"
  }, {
    path: "/bus-search/:city_a?/:city_b?/search",
    component: _f664ea66,
    name: "bus-search-city_a-city_b-search"
  }, {
    path: "/bus-search/:city_a?/:city_b?/order/:id",
    component: _03d31fe4,
    name: "bus-search-city_a-city_b-order-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
