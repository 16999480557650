export default (context, inject) => {
  const fun = {
    setAgregator(value) {
      context.$config.selectCurrency = value
      context.$axios.defaults.baseURL = context.$config.base_host + '/' + context.$config.currency[context.$config.selectCurrency].shortName + '/'
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
    }
  }
  inject('srv', fun)
}

