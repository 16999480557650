export const state = () => ({
  pointA: null,
  pointB: null,
  date: null,
  count: 1,
  loading: false,
})

export const mutations = {
  setProp(state, data) {
    state[data.prop] = data.value
  },
  setLoading(state, data) {
    state.loading = data;
  },
  clearData(state, data) {
    state[data] = null;
  }
}

export const actions = {
  async search({commit, state, rootGetters}) {
    commit('setLoading', true);
    return  this.$axios.get('api/v1/search/get-reis?departure=' + state.pointA + '&arrival=' + state.pointB + '&date=' + state.date + '&places=' + state.count)
      .then(resp => {
        commit('departure/setFindedDeparture', resp.data.response, {root: true})
        commit('departure/sortByParam', 'date_from', {root: true})
        return true
      })
      .catch( () => {
        return false
      })
      .finally(() => {
      commit('setLoading', false);
    });
  }
}

export const getters = {
  getProp: state => data => {
    return state[data];
  },
  getLoading: state => {
    return state.loading;
  }
}
