export const state = () => ({
  cities: [],
  documents: [],
})

export const mutations = {
  setCities(state, data) {
    state.cities = data
  },
  setDocuments(state, data) {
    state.documents = data
  }
}

export const actions = {
  async getCities({commit, rootState}) {
    await this.$axios.get(`${this.$config.base_host}/cp/get/?q=cities`).then(resp => {
      commit('setCities', resp.data.map(el => {
        let name_city = el.name
        let country_city = el.countryName.country_ru

        return {
          id: el.id,
          name: name_city,
          for_url: el.name_en.toLowerCase(),
          country: country_city,
          latitude: el.latitude,
          longitude: el.longitude,
        };

      }))
    });
  },
  async getDocuments({commit}) {
    await this.$axios.get(`${this.$config.base_host}/cp/get/?q=get-documents`).then(resp => {
      commit('setDocuments', resp.data)
    });
  },

}

export const getters = {
  getCities: state => state.cities,
  getCityForUrl: state => data => {
    let url = ''
    state.cities.forEach((val) => {
      if (val.name === data) {
        return url = val.for_url
      }
    })
    return url
  },
  getUrlForCity: state => data => {
    let name = ''
    state.cities.forEach((val) => {
      if (val.for_url === data) {
        return name = val.name
      }
    })
    return name
  },
  getUrlMapCity: state => data => {

    let coordinates = [29.2281209,53.1449683]

    state.cities.forEach((val) => {
      if (val.name === data) {
        return coordinates = [val.longitude, val.latitude]
      }
    })

    return coordinates
  },
  getDocuments: state => state.documents,
  getCityByName: state => data => {
    let obj = ''
    state.cities.forEach((val) => {
      if (val.name === data) {
        return obj = val;
      }
    });
    return obj;
  },
  sortArrByParam: state => data => {
    let paramField = data.params[0] === 'date' ? 'date_create' : 'rate';
    let paramSort = data.params[1] === 'more';

    data.arr.sort((a, b) => {
      if (a[paramField] > b[paramField]) {
        return paramSort ? -1 : 1;
      } else if (a[paramField] < b[paramField]) {
        return paramSort ? 1 : -1;
      }
      return 0;
    });

  }
}
