import Vue from 'vue';
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VCalendar from 'v-calendar';
import createPersistedState from 'vuex-persistedstate'

Vue.use(VCalendar, {
  popover_: {
    visibility: 'click'
  },
});

Vue.component('v-select', vSelect)
Vue.use(Vuelidate)


export default function (context) {

  let host = location.hostname.split('.').filter((_, i) => i < 2).join('.')
  let domain = host.split('.')[1]
  if (domain === 'locl') {
    context.$config.selectCurrency = 'BYN'
  } else if (domain === 'by') {
    context.$config.selectCurrency = 'BYN'
  } else if (domain === 'ru') {
    context.$config.selectCurrency = 'BYN'
  }

  context.$config.url_location = location.hostname
  context.$axios.defaults.baseURL = context.$config.base_host + '/' + context.$config.currency[context.$config.selectCurrency].shortName + '/'

  createPersistedState({
    key: 'app',
    paths: ['checkout', 'account', 'departure', 'search']
  })(context.store)
}
