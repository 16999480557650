export const state = () => ({
  content: {},
  contents: [],
})

export const mutations = {
  setState(state, data) {
    state[data.key] = data.prop
  },
  clearContent(state) {
    state.content = new Content();
  }
}

export const actions = {
  async getByCategory({commit, getters}, cat) {
    await this.$axios.get(`api/v1/content/get-news-by-category?category=${cat}`).then(resp => {
      commit('setState', {key: 'content', prop: resp.data});
    })
    return getters['getByProp']('content');
  },
  async getAllContents({commit, getters}, cat) {
    await this.$axios.get(`api/v1/content/get-contents?category=${cat}`).then(resp => {
      commit('setState', {key: 'contents', prop: resp.data});
    })
    return getters['getByProp']('contents');
  }

}

export const getters = {
  getByProp: state => data => {
    return state[data];
  },
}
