import Vue from 'vue'
import moment from 'moment'

Vue.config.ignoredElements = [/^ion-/]

Vue.filter('timeformat', function (value) {
  return moment(value).format('HH:mm ')
});

Vue.filter('dateFormatName', function (value) {
  let format = moment(value).format('ddd. DD.MM ');
  return format[0].toUpperCase() + format.substring(1)
});

Vue.filter('dateTimeFormatName', function (value) {
  let format = moment(value).format('ddd. DD MMM HH:mm');
  return format[0].toUpperCase() + format.substring(1)
});

Vue.filter('dateFormatNameFull', function (value) {
  return moment(value).format('DD MMMM')
});

Vue.filter('timeFormatName', function (value) {
  let time = value.split(':');
  return `${time[0]}ч. ${time[1]}мин.`
});

Vue.filter('placeFree', function (value) {
  if (parseInt(value) <= 10) return value
  else return '10 +'
});

Vue.filter('dateFormatShort', function (value) {
  return moment(value).format('Do MMM YYYY')
});

Vue.filter('datetimeformat', function (value) {
  if (value)
    return moment(value).format('DD.MM.YYYY HH:mm ')
  else
    return '-'
});

Vue.filter('dayname-dateformat', function (value) {
  if (value)
    return moment(value).format('ddd. DD.MM.YYYY')
  else
    return '-'
});

Vue.filter("date-away", function (a, b) {
  var d0 = new Date(a);
  var d1 = new Date(b);
  var day = ((d1.getTime() - d0.getTime()) / (1000 * 60 * 60 * 24)) % 24 | 0;
  var hour = ((d1.getTime() - d0.getTime()) / (1000 * 60 * 60)) % 24 | 0;
  var minut = ((d1.getTime() - d0.getTime()) / 1000 / 60) % 60 | 0;
  return (
    Math.round(day) +
    " д " +
    Math.round(hour) +
    " ч." +
    Math.round(minut) +
    " мин."
  );
});

Vue.filter("timeDuration", function (a) {
  var day = (parseInt(a) / (60 * 60 * 24)) % 24 | 0;
  var hour = (parseInt(a) / (60 * 60)) % 24 | 0;
  var minut = (parseInt(a) / 60) % 60 | 0;
  return (
    Math.round(day) +
    " д " +
    Math.round(hour) +
    " ч. " +
    Math.round(minut) +
    " мин."
  );
});

Vue.filter('dateFormat', function (value) {
  return moment(value).format('DD.MM.YYYY')
});

Vue.filter("date-away-nomin", function (a, b) {
  var d0 = new Date(a);
  var d1 = new Date(b);
  var hour = ((d1.getTime() - d0.getTime()) / (1000 * 60 * 60)) % 24 | 0;
  var minut = ((d1.getTime() - d0.getTime()) / 1000 / 60) % 60 | 0;
  return (
    Math.round(hour) +
    "ч " +
    Math.round(minut) +
    "мин"
  );
});


Vue.filter('minSecformat', function (value) {
  return moment(value).format('mm:ss')
});


Vue.filter('priceFloat', value => Number(value).toFixed(2)
)

Vue.filter("timeFromDateAndTime", function (a, b) {
  return moment(a + ' ' + b).format('HH:mm')
});

Vue.filter('priceFloatAfterDoath', (val) => {
  return val.toFixed(2).split('.')[1];
})

Vue.filter('priceFloatBeforeDoath', (val) => {
  return Math.trunc(parseFloat(val));
})

Vue.filter('timeFromString', (val) => {
  let time = val.split(':');
  return time[0] + ':' + time[1];
})

Vue.filter('capitalizeFirstLetter', (string) => {
  if (typeof string === 'string' || string instanceof String) {
    return string[0].toUpperCase()
  }
  return 'O'
})







