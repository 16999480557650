export default function(ctx) {

  let redirect_to = ctx.route.fullPath
  const isUpperCase = (string) => ctx.route.fullPath.toLowerCase() === string

  /*
      Проверяем редикет на множественные слэши
  */
  if(/\/{2,}/g.test(redirect_to)) {
    redirect_to = redirect_to.replace(/\/{2,}/g,'/')
  }

  /*
      Если страница не главная и на конце есть слэш, редиректим на URL без слэша
  */
  if (redirect_to !== '/' && redirect_to.substr(redirect_to.length - 1) === '/') {
    redirect_to = redirect_to.slice(0, -1)
  }

  /**
   * Будем приводить редиректить если есть в URL верхний регистр
   * Подумать может совместить со всеми
   */

  if (!isUpperCase(redirect_to)) {
    redirect_to = redirect_to.toLowerCase()
  }

  if (redirect_to !== ctx.route.fullPath) {
    ctx.redirect(301, redirect_to)
  }

}
