
  export default {
    name: "BtnPrimary",
    props: ['str', 'icon', 'showlg'],
    mounted() {
      let btn = this.$el;
      btn.addEventListener('click', (e) => {
        const
          size = Math.max(btn.offsetWidth, btn.offsetHeight),
          x = e.offsetX - size / 2,
          y = e.offsetY - size / 2,
          wave = document.createElement('span')

        // Create a new wave
        wave.className = 'wave'
        wave.style.cssText = `width:${size}px;height:${size}px;top:${y}px;left:${x}px`
        btn.appendChild(wave)

        // Remove element after animation ends
        setTimeout(() => wave.remove(), 500)
      })
    }
  }
