import Vue from 'vue'

export const state = () => ({
  timer_id: 0,
  order_pay: null,
  currency: null,
  price: null,
  new_checkout: null,
  checkout: null,
  passenger: {},
  dopInfo: {
    commonNumber: true,
    number: null,
    email: null,
    comment: null,
  },
  places: {}
})

export const mutations = {
  setRefresh(state) {
    clearInterval(state.timer_id)
    state.timer_id = 0
    state.order_pay = null
    state.currency = null
    state.price = null
    state.new_checkout = null
    state.checkout = null
    state.passenger = {}
    state.dopInfo = {}
    state.places = {}
  },
  setNewCheckout(state, data) {
    state.new_checkout = data
  },
  setTimer(state, data) {
    state.timer_id = data
  },
  setPassenger(state, data) {
    state.passenger = {}
  },
  setPlaces(state, data) {
    state.places = {}
  },
  setCheckoutLockMin(state, data) {
    state.checkout.lock_min = data
  },
  setCheckout(state, data) {
    state.checkout = data
    if (data) {
      data['reis_checkout'].forEach(item => {
        Vue.set(state.places, item.reis_id, item.place)
      })
    }
  },
  setOrderPayAll(state, data) {
    state.order_pay = data.order
    state.currency = data.currency
    state.price = data.price
  },
  setOrderPay(state, data) {
    state.order_pay = data
  },
  setPassengerAttribute(state, data) {
    try {
      if (!state.passenger[data.number]) {
        Vue.set(state.passenger, data.number, {})
        Vue.set(state.passenger[data.number], data.attribute, data.val)
        Vue.set(state.passenger[data.number], 'doc_type', {})
        Vue.set(state.passenger[data.number], 'sell_type', {})
      }

      if (data.attribute === 'doc_type' || data.attribute === 'sell_type') {
        if (!state.passenger[data.number][data.attribute]) {
          Vue.set(state.passenger[data.number], data.attribute, [])
        }
        Vue.set(state.passenger[data.number][data.attribute], data.reis, data.val)
      } else {
        Vue.set(state.passenger[data.number], data.attribute, data.val)
      }

    } catch(e) {
      console.log(e)
    }

  },
  setDopAttribute(state, data) {
    try {
      Vue.set(state.dopInfo, data.attribute, data.val)
    } catch(e) {
      console.log(e)
    }

  },
  setPassengerPlace(state, data) {
    if (!state.passenger[data.number])
      Vue.set(state.passenger, data.number, {})

    if (!state.passenger[data.number]['places']) {
      Vue.set(state.passenger[data.number], 'places', {})
    }

    if (state.passenger[data.number]['places'])
      Vue.set(state.passenger[data.number]['places'], data.reis, {id: data.val, number_seat: data.number_seat})

  },
  cleanPassengerPlace(state, data) {
    delete state.passenger[data.number]['places'][data.reis]
  },
}

export const actions = {
  async newCheckout({state, commit, dispatch}, val) {
    await commit('setPassenger', {})
    await commit('setNewCheckout', null)
    return this.$axios.post('api/v1/checkout/new', val)
      .then(resp => {
        commit('setNewCheckout', resp.data.response)
        commit('setCheckout', null)
        commit('setOrderPay', null)
        commit('setPlaces', null)
        return this.$axios.post('api/v1/checkout/get', {id: resp.data.response.id})
          .then(resp => {
            commit('setCheckout', resp.data.response)
            commit('setOrderPayAll', {
              order: null,
              currency: resp.data.response.currency,
              price: resp.data.response.price
            })
            return resp.data
          })
      });
  },
  async updateCheckout({state}) {
    if (state.new_checkout) {
      this.$axios.post('api/v1/checkout/update', {
        id: state.new_checkout.id,
        checkout_id: state.order_pay,
      })
          .then(resp => {
          })
    }
  },
  async checkCheckout({state}) {
    if (state.new_checkout) {
     return this.$axios.post('api/v1/checkout/check-status', {
        id: state.new_checkout.id,
        checkout_id: state.order_pay,
      })
         .then(resp => {
           return resp.data
         })
         .catch(() => {
           return false
         })

    }
    return false
  },
  async cancelOrder({state}) {
    if (state.new_checkout) {
      this.$axios.post('api/v1/checkout/order-cancel', {
        csrf: state.new_checkout.csrf,
        checkout_id: state.order_pay,
      })
          .then(resp => {
          })
    }
  }
}

export const getters = {
  new_checkout: state => state.new_checkout,
  checkout: state => state.checkout,
  places: state => state.places,
  price: state => state.price,
  currency: state => state.currency,
  checkoutGetLockMin: state => state.checkout ? state.checkout.lock_min : 0,
  orderpay: state => state.order_pay,
  passenger: state => state.passenger,
  getPassengerObj: state => data => state.passenger[data],
  dop: state => state.dopInfo,
}
