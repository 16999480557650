
  import BtnPrimary from "../components/BtnPrimary";

  export default {
    name: "error-page",
    components: {BtnPrimary},
    head: {
      title: 'Страница не найдена',
      description: 'Страница не найдена'
    }
  };
