import CryptoJS from "crypto-js";
import axiosRetry from "axios-retry"

function CryptoJSAesEncrypt(passphrase, plain_text){

  var salt = CryptoJS.lib.WordArray.random(256);
  var iv = CryptoJS.lib.WordArray.random(16);

  var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999 });
  var encrypted = CryptoJS.AES.encrypt(plain_text, key, {iv: iv});

  var data = {
    ciphertext : CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
    salt : CryptoJS.enc.Hex.stringify(salt),
    iv : CryptoJS.enc.Hex.stringify(iv)
  }

  return JSON.stringify(data);
}

export default function ({ $axios, $config }) {

  const passphrase = $config.passphrase + 'slFU99bS67qLU5UiLifTYPIOCCHvzBB4g';
  let data_json = ''
  const time_token = 60 * 60
  let data = {
    user: 'front',
    expired: (new Date(new Date().toLocaleString('en-US', {
      timeZone: "Europe/Minsk"
    })).getTime() / 1000 + Number(time_token))
  }
  data_json = CryptoJSAesEncrypt(passphrase, JSON.stringify(data));
  $axios.setHeader('Okassa', data_json)

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 449) {
      data = {
        user: 'front',
        expired: (new Date(new Date().toLocaleString('en-US', {
          timeZone: "Europe/Minsk"
        })).getTime() / 1000 + Number(time_token))
      }
      data_json = CryptoJSAesEncrypt(passphrase, JSON.stringify(data));
      $axios.setHeader('Okassa', data_json)
    }
  })

  axiosRetry($axios, {
    retries: 1,
    retryDelay: (retryCount, error) => {
      error.config.headers['Okassa'] = data_json
    },
    retryCondition: (error) => {
      if (error.response.status === 449) {
        return true
      }
    },
  })

}
