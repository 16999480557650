import Vue from 'vue'
import Dialog from 'element-ui/packages/dialog'
import Rate from 'element-ui/packages/rate'
import Popover from 'element-ui/packages/popover'
import InputNumber from 'element-ui/packages/input-number'
import Button from 'element-ui/packages/button'
import SkeletonItem from 'element-ui/packages/skeleton-item'
import Skeleton from 'element-ui/packages/skeleton'
import Loading from 'element-ui/packages/loading'
import Notification from 'element-ui/packages/notification'
import Collapse from 'element-ui/packages/collapse'
import CollapseItem from 'element-ui/packages/collapse-item'


Vue.component(Dialog.name, Dialog)
Vue.component(Rate.name, Rate)
Vue.component(Popover.name, Popover)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Button.name, Button)
Vue.component(SkeletonItem.name, SkeletonItem)
Vue.component(Skeleton.name, Skeleton)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)
Vue.prototype.$notify = Notification;
Vue.use(Loading)


